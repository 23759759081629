import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import Loader from "../../components/Loader";
import { GenerateAltitudeQuiz, GenerateGaugeImage, getAllOptions, saveHorizon } from "../../helpers/api/quiz";
import { getFile } from "../../helpers/api/file";
import { useUploadFile } from "../../hooks/useUploadFile";
import PageTitleBox from "../../components/PageTitleBox";
import _ from "lodash";

interface Question {
  fileUrlFull: any;
  quizId: number;
  fileId: string | number;
  questionText: string;
  fullFileUrl: string;
  answers: { fileId: number }[];
}

const defaultValues: {
  Id: number;
  questionFileId: number;
  answerFileId: number;
  option1: number;
  option2: number;
  option3: number;
  fileUrlFull: string;
  fileId: string | number;
} = {
  Id: 0,
  questionFileId: 0,
  answerFileId: 0,
  option1: 0,
  option2: 0,
  option3: 0,
  fileUrlFull: "",
  fileId: "",
};

const QuizViewDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [question, setQuestion] = useState<Question | null>(null);
  const [answer, setAnswer] = useState<any[]>([]);
  const [correctAnswerFileId, setCorrectAnswerFileId] = useState<any>();
  const [apiStatus] = useState({
    inprogress: false,
  });
  const fileUploadRef = useRef<any>(null);  
  const [optionImages, setOptionImages] = useState<any[]>([]);
  const [radioDisabled, setRadioDisabled] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
  const [watchCategoryImages, setWatchCategoryImages] = useState<string[]>(["", "", "", ""]);
  const [editMode, setEditMode] = useState(false);
  const [horizonAngle, setHorizonAngle] = useState("");
  const [compassAngle, setCompassAngle] = useState("");
  const [dip, setDip] = useState("");
  const [gaugeImageUrl, setGaugeImageUrl] = useState<string | null>(null); 
  const [gaugeId, setGaugeId] = useState<any | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<{
    Id: number;
    questionFileId: number;
    answerFileId: number;
    option1: number;
    option2: number;
    option3: number;
    fileUrlFull: string;
    fileId: string | number;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });

  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    Id: number;
    questionFileId: number;
    answerFileId: number;
    option1: number;
    option2: number;
    option3: number;
    fileUrlFull: string;
    fileId: string | number;
  }> = async (data) => {    try {
      if (question) {
        const filteredOptions = options.filter(option => option.fileId !== correctAnswerFileId);
        const payload = {
          Id: id,
          fileId: correctAnswerFileId,
          questionFileId: gaugeId || question.fileId,
          answerFileId: correctAnswerFileId || answer,
          option1: filteredOptions[0]?.fileId || 0,
          option2: filteredOptions[1]?.fileId || 0,
          option3: filteredOptions[2]?.fileId || 0,
          fileUrlFull: question.fileUrlFull,
          horizonAngle,
          compassAngle,
          dip,
        };

        const response = await saveHorizon(payload);
        navigate(-1);

        if (response.statusCode === 200) {
          showToast("success", response.message);
        } else {
          console.log(response.error);
          showToast("error", response.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoading(true);
    try {
      const response = await GenerateAltitudeQuiz({
        pageNumber: 1,
        pageSize: 50,
        takeRandom: false,
        quizId: id,
      });
      const question = response.data[0];
      reset({
        Id: question.quizId,
        fileId: response.data.fileId,
        questionFileId: question.fileId,
        answerFileId: 0,
        fileUrlFull: question.fileUrlFull,
      });
      setData(response.data);
      setOptions(question.answers);
      setQuestion(question);
      setCorrectAnswerFileId(question.correctAnswer.fileId);
      setAnswer(question.correctAnswer.fileId);
      setLoading(false);
      getImage(response.data.fileId);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  async function getImage(fileId: number) {
    let response = await getFile({ id: fileId });
    try {
      if (response.statusCode === 200) {
        setValue("fileUrlFull", response.data.fullFileUrl);
        console.log(response.message);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getGaugeImage() {
    let response = await GenerateGaugeImage({
      horizonAngle: parseFloat(horizonAngle)|| 0,
      compassAngle: parseFloat(compassAngle) || 0,
      dip: parseFloat(dip)||0,
    });
    try {
      if (response.statusCode === 200) {
        setGaugeImageUrl(response.data.fullFileUrl); 
        setGaugeId(response.data.id)
        setEditMode(false);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const GetOptions = async () => {
    try {
      const response = await getAllOptions();
      setOptionImages(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const f = e.target.files;
      if (f) {
        multiUploadFile(f, {
          isPlaneImage: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const { uploading, multiUploadFile } = useUploadFile({
    onSuccess: (fileData: any) => {
      onFileUploaded(fileData);
    },
  });

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);

  const uploadFile = (index: number) => {
    setSelectedOptionIndex(index);
    if (fileUploadRef && !uploading) {
      fileUploadRef?.current?.click();
    }
  };

  const onFileUploaded = (file: any) => {
    if (selectedOptionIndex !== null) {
      const updatedOptions = [...options];
      updatedOptions[selectedOptionIndex] = { ...updatedOptions[selectedOptionIndex], fileFullUrl: file.fullFileUrl, fileId: file.id };
      setOptions(updatedOptions);
      setRadioDisabled(false);

      setCorrectAnswerFileId(file.id);
      if (selectedOptionIndex === 0) {
        setValue("questionFileId", file.id);
        setQuestion((prevQuestion) => prevQuestion ? { ...prevQuestion, fileId: file.id } : null);
      }
    }
  };

  useEffect(() => {}, [correctAnswerFileId]);

  const handleRadioChange = (fileId: any | string) => {
    setCorrectAnswerFileId(fileId); 
  };

  return (
    <>
      <PageTitleBox name="Question Detail" pageTitle="  Question Detail " />
      <form name="chat-form" id="chat-form" onSubmit={handleSubmit(onSubmit)}>
        <Card className="shadow-none">
          <Card.Body>
            {loading && <Loader />}
            {!loading && (
              <>
                <Row className="mb-4 align-items-center ">
                  <Col xl={12}>
                    {data.map((question: any, index: any) => (
                      <Row key={index} className="mb-4">
                        <Col md={6} className="text-center">
                          <p>{question.questionText}</p>
                          <div>
                            {gaugeImageUrl ? (
                              <img
                                src={gaugeImageUrl}
                                alt="Gauge Image"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={question.fullFileUrl}
                                alt="Question"
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="text-end mx-5 text-decoration-underline cursor-pointer" onClick={() => setEditMode(true)}>
                            Edit
                          </div>
                          {editMode && (
                            <div className="mt-3 mx-5">
                              <Form.Group controlId="horizonAngle" className="mb-2" style={{ width: "50%" }}>
                                <Form.Label>Angle of Bank</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={horizonAngle}
                                  onChange={(e) => setHorizonAngle(e.target.value)}
                                  min={0}
                                  max={360}
                                />
                              </Form.Group>
                              <Form.Group controlId="compassAngle" className="mt-2 mb-2" style={{ width: "50%" }}>
                                <Form.Label>Compass heading</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={compassAngle}
                                  onChange={(e) => setCompassAngle(e.target.value)}
                                  min={0}
                                  max={360}
                                />
                              </Form.Group>
                              <Form.Group controlId="dip" className="mt-2" style={{ width: "50%" }}>
                                <Form.Label>Pitch</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={dip}
                                  onChange={(e) => setDip(e.target.value)}
                                  min={-5}
                                  max={5}
                                  step="0.1"
                                />
                              </Form.Group>
                              <Button
                                variant="primary"
                                className="mt-3"
                                onClick={() => getGaugeImage()}
                              >
                                Save
                              </Button>
                              <Button
                                variant="primary"
                                className="mt-3 mx-3"
                                onClick={() => setEditMode(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col xl={12}>
                    <Row>
                      {options.map((answer: any, index: any) => (
                        <Col md={6} key={answer.id}>
                          <Form.Check
                            type="radio"
                            name="answer"
                            id={`answer-${index}`}
                            onChange={() => handleRadioChange(answer.fileId)}
                            checked={correctAnswerFileId === answer.fileId}
                            disabled={radioDisabled}
                          >
                            <Form.Check.Input
                              type="radio"
                              name="answer"
                              id={`answer-${index}`}
                              value={answer.fileId}
                              checked={correctAnswerFileId === answer.fileId}
                              onChange={() => handleRadioChange(answer.fileId)}
                              disabled={radioDisabled}
                            />
                            <Form.Check.Label>
                              <img
                                src={answer.fileFullUrl}
                                alt={`Answer ${index + 1}`}
                                className="img-fluid"
                              />
                            </Form.Check.Label>
                            <span className="position-absolute top-0 mx-5 end-0 pt-1 pe-1">
                              {!apiStatus.inprogress && (
                                <label>
                                  <input
                                    ref={fileUploadRef}
                                    type="file"
                                    className="d-none"
                                    onChange={(e) => handleImage(e)}
                                  />
                                </label>
                              )}
                              {watchCategoryImages[index] ? (
                                <img
                                  src={watchCategoryImages[index]}
                                  alt={`Option ${index + 1}`}
                                  className="img-fluid mb-3"
                                  onClick={() => uploadFile(index)}
                                  style={{ width: "50%", height: "auto" }}
                                />
                              ) : (
                                <div className="text-decoration-underline" style={{ cursor: "pointer" }} onClick={() => uploadFile(index)}>Upload</div>
                              )}
                            </span>
                          </Form.Check>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="px-4 save-btn"
                  onClick={GetOptions}
                >
                  Change correct answer
                </Button>
                <Row className="mt-5 mx-5">
                  {optionImages.map((answer: any, index: any) => (
                    <Col md={3} key={answer.id}>
                      <Form.Check
                        type="radio"
                        name="answer"
                        id={`answer-${index}`}
                        onChange={() => handleRadioChange(answer.fileId)}
                        disabled={radioDisabled}
                      >
                        <Form.Check.Input
                          type="radio"
                          name="answer"
                          id={`answer-${index}`}
                          value={answer.fileId}
                          onChange={() => handleRadioChange(answer.fileId)}
                          disabled={radioDisabled}
                        />
                        <Form.Check.Label>
                          <img
                            src={answer.fullFileUrl}
                            alt={`Answer ${index + 1}`}
                            className="img-fluid"
                          />
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="button-list">
            <Button
              variant="primary"
              type="submit"
              disabled={apiStatus.inprogress || uploading}
              className="px-4 save-btn"
            >
              Save
            </Button>
            <Button className="px-4 cancel-btn" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default QuizViewDetails;
