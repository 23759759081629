import { APICore } from "./apiCore";

const api = new APICore();

function saveTopic(params:any) {
    const baseUrl = "/api/Topic/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllTopic(params: {
    pageNumber: number;
    pageSize: number;
    parentId?: string | number;
    // query: string;
   
  }){
    const baseUrl = "/api/Topic/GetAll";
    return api.get(`${baseUrl}`, params);
}


const deleteTopic = (id: any) => {
    const baseUrl = "/api/Topic/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { saveTopic, getAllTopic, deleteTopic }
