import { APICore } from "./apiCore";

const api = new APICore();

function saveUserAnswer(params: any) {
    const baseUrl = "/api/QuizAnswer/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllUserAnswer(params: {
    pageNumber: number;
    pageSize: number;
    questionId: number | string;
    //  query: string;

}) {
    const baseUrl = "/api/QuizAnswer/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getUserAnswer(params: { id: number | string }) {
    const baseUrl = "/api/QuizAnswer/Get";
    return api.get(`${baseUrl}`, params);
}

const deleteUserAnswer = (id: any) => {
    const baseUrl = "/api/QuizAnswer/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { saveUserAnswer, getUserAnswer, getAllUserAnswer, deleteUserAnswer }