import { APICore } from "./apiCore";

const api = new APICore();

function saveQuiz(params: any) {
    const baseUrl = "/api/Quiz/Save";
    return api.create(`${baseUrl}`, params);
}

function saveHorizon(params: any) {
    const baseUrl = "/api/Quiz/SaveHorizonQuiz";
    return api.create(`${baseUrl}`, params);
}

function getAllQuiz(params: {
    pageNumber: number;
    pageSize: number;
    topicId: number;
    //  query: string;

}) {
    const baseUrl = "/api/Quiz/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getQuestionTypes(params: any) {
    const baseUrl = "/api/Quiz/GetQuestionTypes";
    return api.get(`${baseUrl}`, params);
}

function getAllOptions() {
    const baseUrl = "/api/Quiz/GetAllHorizonQuizFiles";
    return api.get(baseUrl);
}



function getQuiz(params: any) {
    const baseUrl = "/api/Quiz/Get";
    return api.get(`${baseUrl}`, params);
}
function GenerateGaugeImage(params: {
    horizonAngle: number;
    compassAngle: number;
    dip: any;
}) {
    const baseUrl = "/api/Quiz/GenerateAltitudeGaugeImage";
    return api.get(`${baseUrl}`, params);
}

function GenerateAltitudeQuiz(params: {
    pageNumber: number;
    pageSize: number;
    takeRandom: boolean;
    quizId?:number;
}) {
    const baseUrl = "/api/Quiz/GenerateAltitudeQuiz";
    return api.get(`${baseUrl}`, params);
}
function SaveRPMQuiz(params: any) {
    const baseUrl = "/api/Quiz/SaveRPMQuiz";
    return api.create(`${baseUrl}`, params);
}
function GenerateRpmQuiz(params: any) {
    const baseUrl = "/api/Quiz/GenerateRpmQuiz";
    return api.get(`${baseUrl}`, params);
}


function getHorizonQuiz(params: any) {
    const baseUrl = "/api/Quiz/GetAllHorizonQuiz";
    return api.get(`${baseUrl}`, params);
}

const deleteQuiz = (id: any) => {
    const baseUrl = "/api/Quiz/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { saveQuiz, getHorizonQuiz,GenerateGaugeImage, getAllOptions,getAllQuiz, deleteQuiz ,getQuiz,saveHorizon , getQuestionTypes, GenerateRpmQuiz, SaveRPMQuiz, GenerateAltitudeQuiz}