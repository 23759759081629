import React from "react";
import { Row, Col } from "react-bootstrap";
import { useUser } from "../hooks";

interface PageTitleBoxProps {
	name: string;
	pageTitle: string;
	rightItem?: any;
}

/**
 * PageTitleBox
 */
const PageTitleBox = ({ name, pageTitle, rightItem }: PageTitleBoxProps) => {
    const { user } = useUser()
	return (
		<Row>
			<Col>
				<div className="page-title-box">
					<div>
						{/* <h3 className="mb-2 text-color-blue fs-2">{user?.TeamName}</h3> */}
						<h4 className="page-title text-muted">{pageTitle}</h4>
					</div>
					<div className="page-title-right">{rightItem}</div>
				</div>
			</Col>
		</Row>
	);
};

export default PageTitleBox;
