import { APICore } from "./apiCore";

const api = new APICore();

// vendor
function getFiles(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
}) {
	const baseUrl = "/api/File/GetAll";
	return api.get(`${baseUrl}`, params);
}

function getFile(params: { id: number | string }) {
	const baseUrl = "/api/File/Get";
	return api.get(`${baseUrl}`, params);
}

function saveFile(params: any, config?:any) {
	const baseUrl = "/api/File/Save";
	return api.fileUpload(`${baseUrl}`, params, config);
}

function deleteFile(id: string | number) {
	const baseUrl = "/api/File/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}

export { getFiles, getFile, saveFile, deleteFile };