import { useState } from "react";
import { saveFile } from "../helpers/api/file";


interface useUploadFileOptionsProps {
	onSuccess?: (res: any) => any;
	onError?: (error: Error) => any;
	onFire?: () => void;
	onPercentage?: (percentage: number, totalUploaded:number, totalFilesCount: number) => void;
    onCompleted?: (res: Array<any>) => void;
}

export const useUploadFile = (options?: useUploadFileOptionsProps) => {
	const {
		onSuccess = (res: any) => res,
		onError = (error: Error) => error,
		onFire = () => null,
		onPercentage = (num: number, totalUploaded:number, totalFilesCount: number) => null,
        onCompleted = (res: Array<any>) => null
	} = options || {};

	const [percentage, setPercentage] = useState("");
	const [uploading, setUploading] = useState(false);
	const [uploadError, setUploadError] = useState<any>();
	const [result, setResult] = useState<{ [x: string]: any }>({});
    const [results, setResults] = useState<{ [x: string]: any }[]>([]);
    const [total, setTotal] = useState<number>(1);
    const [totalUploaded, setTotalUploaded] = useState<number>(0)

	const uploadFile = async (data: File, additionalData?: {[x:string]: any}, Type?: string, totalCount = total, totalUploadedCount = totalUploaded) => {
		try {
			const formData = new FormData();
			formData.append("file", data);
            const tempFileType = data.type.split("/")?.shift();
            console.log('tempFileType', tempFileType);
			if (Type) {
				formData.append("Type", Type);
			} else if (tempFileType) {
				formData.append("Type", tempFileType);
            }
			// 
			const keys = Object.keys(additionalData || {});
			keys.forEach((key)=>{
				if(additionalData){
					formData.append(key, additionalData[key]);
				}
			})
			// 
			onFire();
			setUploading(true);
			setResult({});
			const fileRes = await saveFile(formData, {
				onUploadProgress: (progressEvent: any) => {
					const progress = `${Math.ceil(
						(progressEvent.loaded / progressEvent.total) * 100,
					)}`;
                    const totalUploadedPercentage = ((totalUploaded * 100) + +progress) / totalCount;
					onPercentage(+progress, totalUploadedCount + 1, totalCount);
					setPercentage(""+totalUploadedPercentage);
				},
			});
			console.log("fileRes", fileRes);
			setPercentage("");
			setUploading(false);
            const tempResults = results;
            tempResults.push(fileRes.data)
            console.log("totalUploadedCount", totalUploadedCount);
            console.log("totalCount", totalCount);
            setTotalUploaded(totalUploadedCount + 1);
            setResults(tempResults);
			setResult(fileRes.data);
			onSuccess(fileRes.data);
			return result;
		} catch (error: any) {
			onError(error);
			setUploadError(error.message);
			setPercentage("");
			setUploading(false);
			return null;
		}
	};

    const reset = () => { 
        setTotal(1);
        setTotalUploaded(0);
        setPercentage("");
        setResults([])
    }

    const multiUploadFile = async (fileList: FileList, additionalData?: {[x:string]: any}, type?:string) => {
        const files: File[] = Array.from(fileList);
        const filenames = files.map((file) => file?.name);
        const len:number = filenames.length;
        console.log(filenames)
        console.log(files, len);
        setTotalUploaded(0);
        setTotal(len);
        console.log(total);
        setPercentage("")
        for (let i = 0; i < len; i++) { 
            console.log(`Count ${i} upload`)
            const file = files[i];
            await uploadFile(file, additionalData || {}, type, len, i);
        }
        console.log("multiUploadFile - Done", results)
        onCompleted(results);
        reset()
    }

	return {
		percentage,
		uploading,
		uploadFile,
        multiUploadFile,
		error: uploadError,
		data: result,
	};
};
