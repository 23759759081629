import { useEffect } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import Loader from "../../components/Loader"
import _ from "lodash";
import { GenerateRpmQuiz, SaveRPMQuiz, saveHorizon } from "../../helpers/api/quiz";

interface Answer {
  id: number;
  questionId: number;
  answerText: string;
  sortOrder: number | null;
  addedBy: number;
  addedOn: string;
  modifiedBy: number;
  modifiedOn: string;
  fileId: number;
  fileUrl: string | null;
  answerType: string;
  fileFullUrl: string;
}


//Default values
const defaultValues: {
  Id: number;
  questionFileId: number;
  option1: number;
  option2: number;
  option3: number;
  answer: number;
} = {
  Id: 0,
  questionFileId: 0,
  option1: 0,
  option2: 0,
  option3: 0,
  answer: 0,
};

const QuizDetailsView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { showToast, dissmisToast } = useToast();
  const [loading, setLoadoing]: any = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [question, setQuestion] = useState<any[]>([]);
  const [answer, setAnswer] = useState<any[]>([]);
  const [correctAnswerId, setCorrectAnswerId] = useState<any>(null);
  const [apiStatus] = useState({
    inprogress: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm<{
    Id: number;
    questionFileId: number;
    option1: number;
    option2: number;
    option3: number;
    answer: number;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    Id: number;
    questionFileId: number;
    option1: number;
    option2: number;
    option3: number;
    answer: number;
  }> = async (formData) => {
    try {
      const requestParams: any = {
        Id: id,
        questionFileId: formData.questionFileId,
        answer: Number(correctAnswerId),
      }
      const optionsKey = ["option1", "option2", "option3"];
      const _options = [...options].filter((el) => (el.answerText != correctAnswerId));
      optionsKey.forEach((key) => {
        requestParams[key] = _options.pop().answerText
      })

      const response = await SaveRPMQuiz({ ...requestParams });
      navigate(-1);

      if (response.statusCode === 200) {
        showToast("success", response.message);
      } else {
        console.log(response.error);
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoadoing(true);
    try {
      const response = await GenerateRpmQuiz({
        pageNumber: 1,
        pageSize: 50,
        takeRandom: false,
        quizId: id,
      });
      const question = response.data[0];




      reset({
        Id: question.quizId,
        questionFileId: question.fileId,

      });
      setCorrectAnswerId(question.correctAnswer.answerText);
      setData(response.data);
      setOptions(question.answers);
      setQuestion(question.fileId);
      setLoadoing(false);
    } catch (error) {
      setLoadoing(false);
      console.log(error, "error");
    }
  };


  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);
  const handleInputChange = (index: number, value: any) => {
    const updatedOptions = [...options];
    updatedOptions[index].answerText = value;
    setOptions(updatedOptions);
  };
  

  return (
    <>
      <PageTitleBox
        name="Question Detail"
        pageTitle="  Question Detail "
      />
      <form name="chat-form" id="chat-form" onSubmit={handleSubmit(onSubmit)}>
        <Card className="shadow-none">
          <Card.Body className="d-flex">
            {loading && <Loader />}
            {!loading && (
              <>
                <Row className="mb-4">
                  {data.map((question: any, index: any) => (
                    <Col md={12} key={index}>
                      <p>{question.questionText}</p>
                      <div>
                        <img src={question.fullFileUrl} alt="Question" className="img-fluid" />
                      </div>
                    </Col>
                  ))}
                </Row>

                <Row className="mt-5 mx-5">
                  {options.map((answer: any, index: any) => (
                    <Col md={6} key={answer.id}>
                      <label>
                        <Form.Check
                          type="radio"
                          name="answer"
                          id={`answer-${index}`}
                          value={answer.answerText}
                          checked={correctAnswerId === answer.answerText}
                          onChange={(e) => {
                            const { value } = e.target;
                            setCorrectAnswerId(value);
                          }}
                          label={
                            <>
                              <input
                                type="text"
                                defaultValue={answer?.answerText}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                onBlur={(e) => handleInputChange(index, e.target.value)}
                                style={{ width: '80%' }} // Adjust the width as needed
                              />
                            </>
                          }
                        />
                      </label>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="button-list">
            <Button
              variant="primary"
              type="submit"
              disabled={apiStatus.inprogress}
              className="px-4 save-btn"
            >
              Save
            </Button>
            <Button
              className="px-4 cancel-btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>

        </div>
      </form>
    </>
  );
};

export default QuizDetailsView;

